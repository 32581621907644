
import { defineComponent, ref, onMounted, computed, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import useUsers from "@/composables/useUsers";
import {
  CreateOrUpdateUserInput,
  GetUserForEditOutput,
  OrganizationUnitDto,
  UserEditDto,
  UserRoleDto,
} from "@/shared/service-proxies/service-proxies";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "CreateOrUpdateUser",

  setup() {
    const { userForEdit, getUserForEdit, updateOrCreateUser } = useUsers();
    const route = useRoute();
    const router = useRouter();
    const saving = ref(false);
    const setRandomPassword = ref<boolean>(false);
    const confirmPassword = ref<string>("");
    const dataId = computed(() => route.query.id);
    const store = useStore();
    const roleCount = ref<number>(1);
    const breadcrumbs = ref({});

    const validatePassword = (rule: any, value: any, callback: any) => {
      if (value === "") {
        callback(new Error("Please input the password confirmation"));
      } else if (value !== formData.value.user.password) {
        callback(new Error("Password don't match!"));
      } else {
        callback();
      }
    };

    const rules = reactive({
      firstname: [
        {
          required: true,
          message: "Firstname is required",
          trigger: "blur",
        },
      ],
      surname: [
        {
          required: true,
          message: "Surname is required",
          trigger: "blur",
        },
      ],
      emailAddress: [
        {
          required: true,
          message: "Email address is required",
          trigger: "blur",
        },
      ],
      userName: [
        {
          required: true,
          message: "UserName is required",
          trigger: "blur",
        },
      ],
      confirmPassword: [
        {
          validator: validatePassword,
          trigger: "blur",
        },
      ],
    });

    const defaultRole = ref<UserRoleDto[]>([
      {
        isAssigned: false,
        roleDisplayName: "Admin",
        roleName: "Admin",
      },
      {
        isAssigned: false,
        roleDisplayName: "BAT Admin",
        roleName: "2be440dee389495496ab74613fdde919",
      },
      {
        isAssigned: false,
        roleName: "652bc5956dbb48459951291ad684d3e6",
        roleDisplayName: "Contact Center Agent",
      },
      {
        isAssigned: false,
        roleName: "620690c0d01947e185a1f6a77a78e5f6",
        roleDisplayName: "Contact Center Supervisor",
      },
      {
        isAssigned: false,
        roleName: "Agent",
        roleDisplayName: "Retail Agent",
      },
      {
        isAssigned: true,
        roleName: "User",
        roleDisplayName: "User",
      },
      {
        isAssigned: false,
        roleName: "56aeee6d946641a9ac319f257e376692",
        roleDisplayName: "USSD Ordering",
      },
      {
        isAssigned: false,
        roleName: "4db2999e83ab45199a5491868ee7fd49",
        roleDisplayName: "Web Ordering",
      },
    ] as unknown as UserRoleDto[]);

    const defaultData = ref({
      allOrganizationUnits: [] as unknown as OrganizationUnitDto[] | undefined,
      memberedOrganizationUnits: [] as unknown as string[] | undefined,
      profilePictureId: null as null | string,
      roles: [] as unknown as UserRoleDto[] | undefined,
      user: {
        name: "",
        surname: "",
        userName: "",
        emailAddress: "",
        isActive: true,
        shouldChangePasswordOnNextLogin: true,
        isTwoFactorEnabled: true,
        isLockoutEnabled: true,
      } as unknown as UserEditDto | undefined,
    } as unknown as GetUserForEditOutput);

    let formData = ref(defaultData);

    const submit = async () => {
      saving.value = true;

      let assignedRoleNames = [] as unknown as string[];

      formData.value.roles?.forEach((role) => {
        if (role.isAssigned === true) {
          assignedRoleNames.push(role.roleName as string);
        }
      });

      const inputData = {
        assignedRoleNames,
        user: formData.value.user,
        sendActivationEmail: formData.value.user.isTwoFactorEnabled,
        organizationUnits: formData.value.allOrganizationUnits,
        setRandomPassword: setRandomPassword.value,
      } as unknown as CreateOrUpdateUserInput;

      await updateOrCreateUser(inputData);

      const error = store.getters.getErrors;

      if (!error) {
        Swal.fire({
          text: dataId.value
            ? "User updated successfully"
            : "User created successfully",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Okay!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-success",
          },
        });
        router.push({ name: "users" });
      } else {
        Swal.fire({
          text: error,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      }

      saving.value = false;
    };

    onMounted(async () => {
      if (!dataId.value) {
        formData.value.roles = defaultRole.value;
        // setCurrentPageBreadcrumbs("Create User", ["User", "Create"]);
        breadcrumbs.value = {
          title: "Create User",
          breadcrumbs: ["User", "Create"],
        };
        return;
      }

      breadcrumbs.value = {
        title: "Edit User",
        breadcrumbs: ["User", "Edit"],
      };

      // setCurrentPageBreadcrumbs("Edit User", ["User", "Edit"]);
      await getUserForEdit(dataId.value as unknown as number);

      if (userForEdit.value) {
        formData.value = {
          ...userForEdit.value,
        } as unknown as GetUserForEditOutput;

        //Check is password is set
        if (formData.value.user.password === null) {
          setRandomPassword.value = true;
        }
      }

      countRole();
    });

    const countRole = () => {
      let count = 0;
      formData.value.roles?.forEach((role) => {
        if (role.isAssigned === true) {
          count++;
        }
      });
      roleCount.value = count;
    };

    return {
      formData,
      setRandomPassword,
      confirmPassword,
      saving,
      route,
      rules,
      roleCount,
      submit,
      countRole,
      breadcrumbs,
    };
  },
});
